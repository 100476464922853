import React, { lazy, Suspense, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { LanguageContext } from "context";

import FooterCoinzix from "components/organisms/FooterCoinzix/FooterCoinzix";

import {
  ABOUT,
  CAREER,
  ATM,
  TERMS_AND_CONDITIONS,
  RISK_DISCLOSURE,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  TOKENOMICS,
  CEZAR,
} from "./route-codes";
import { history } from "utils/local-history";

import { LANGUAGES } from "components/organisms/SelectLanguage/SelectLanguage";

const AboutUs = lazy(() => import("components/pages/AboutUs/AboutUs"));
const AboutUsRo = lazy(() => import("components/pages/AboutUsRo/AboutUsRo"));
const CareerUs = lazy(() => import("components/pages/CareerUs/CareerUs"));
const JobUs = lazy(() => import("components/pages/JobUs/JobUs"));
const AtmUs = lazy(() => import("components/pages/Atm/Atm"));
const NotFound = lazy(() => import("components/pages/NotFound/NotFound"));
const RiskDisclosure = lazy(() =>
  import("components/pages/DisclosureStatement/DisclosureStatement")
);
const CookiesPolicy = lazy(() =>
  import("components/pages/CookiesPolicy/CookiesPolicy")
);
const PrivacyPolicy = lazy(() =>
  import("components/pages/PrivacyPolicy/PrivacyPolicy")
);
const TermsAndConditions = lazy(() =>
  import("components/pages/TermsAndConditions/TermsAndConditions")
);
const Tokenomics = lazy(() => import("components/pages/Tokenomics/Tokenomics"));
const Cezar = lazy(() => import("components/pages/Cezar/Cezar"));

const CustomRoutes = () => {
  const [currentLanguage, setCurrentLanguage] = useContext(LanguageContext);

  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter history={history}>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          className="czx"
        >
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Navigate
                  to={
                    currentLanguage === LANGUAGES.en
                      ? `en/${ABOUT}`
                      : `ro/${ABOUT}`
                  }
                />
              }
            />
            <Route exact path={`en/${ABOUT}`} element={<AboutUs />} />
            <Route exact path={`ro/${ABOUT}`} element={<AboutUsRo />} />
            {/* <Route exact path={CAREER} element={<CareerUs />} /> */}
            <Route exact path={`:language/${CAREER}`} element={<JobUs />} />
            <Route exact path={`:language/${ATM}`} element={<AtmUs />} />
            <Route
              exact
              path={`:language/${RISK_DISCLOSURE}`}
              element={<RiskDisclosure />}
            />
            <Route
              exact
              path={`:language/${COOKIE_POLICY}`}
              element={<CookiesPolicy />}
            />
            <Route
              exact
              path={`:language/${PRIVACY_POLICY}`}
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path={`:language/${TERMS_AND_CONDITIONS}`}
              element={<TermsAndConditions />}
            />
            <Route exact path={TOKENOMICS} element={<Tokenomics />} />
            <Route exact path={CEZAR} element={<Cezar />} />
            <Route exact path="*" element={<NotFound />} />
          </Routes>
        </div>
        <FooterCoinzix />
      </BrowserRouter>
    </Suspense>
  );
};

export default CustomRoutes;
