import React, { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ABOUT } from "routes/route-codes";
import { LanguageContext } from "context";

import "./SelectLanguage.scss";

export const LANGUAGES = {
  en: "en",
  ro: "ro",
};

const LANGUAGE_LIST = [
  {
    language: "en",
    label: "English",
  },
  {
    language: "ro",
    label: "Română",
  },
];

const SelectLanguage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", LANGUAGES.en);
    }
  }, []);

  const currentLanguageList = useMemo(() => {
    const languageList = LANGUAGE_LIST.filter(
      (lang) => lang.language !== currentLanguage
    );
    return languageList;
  }, [currentLanguage]);

  const currentSelectedLanguage = useMemo(() => {
    const selectedLanguage = LANGUAGE_LIST.find(
      (lang) => lang.language === currentLanguage
    );

    return selectedLanguage;
  }, [currentLanguage]);

  const handleLanguageSelect = async (lang) => {
    await localStorage.setItem("language", lang.language);
    setCurrentLanguage(lang.language);
    setShowMenu(false);

    if (lang.language === currentLanguage) return;

    let newLanguageUrl;

    if (location.pathname.includes(`/${LANGUAGES.en}`)) {
      newLanguageUrl = location.pathname.replace(
        `/${LANGUAGES.en}`,
        `/${LANGUAGES.ro}`
      );
    }

    if (location.pathname.includes(`/${LANGUAGES.ro}`)) {
      newLanguageUrl = location.pathname.replace(
        `/${LANGUAGES.ro}`,
        `/${LANGUAGES.en}`
      );
    }

    navigate(newLanguageUrl || `/${ABOUT}`);
  };

  return (
    <div
      role="presentation"
      onMouseEnter={() => setShowMenu(true)}
      className="czix-select-language"
    >
      <span
        style={{
          display: "flex",
        }}
      >
        {currentSelectedLanguage?.language.toUpperCase()}{" "}
        <span className="arrow-bottom"></span>
      </span>
      {showMenu && (
        <div
          role="presentation"
          onMouseLeave={() => setShowMenu(false)}
          className="czix-select-language__select sublinks"
        >
          {currentLanguageList.map((lang) => (
            <div
              role="presentation"
              key={lang.language}
              onClick={() => handleLanguageSelect(lang)}
              className="czix-select-language__item sublink-item"
            >
              <span>{lang.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLanguage;
