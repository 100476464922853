const ABOUT = "about";
const CAREER = "career";
const ATM = "atm";
const PRIVACY_POLICY = "info/privacy-policy";
const TERMS_AND_CONDITIONS = "info/terms-and-conditions";
const COOKIE_POLICY = "info/cookies-policy";
const RISK_DISCLOSURE = "info/risk-disclosure-statement";
const TOKENOMICS = "tokenomics";
const CEZAR = "cezar";

export {
  ABOUT,
  CAREER,
  ATM,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  COOKIE_POLICY,
  RISK_DISCLOSURE,
  TOKENOMICS,
  CEZAR,
};
