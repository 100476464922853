import React, { useState } from "react";

import Routes from "routes";

import { LanguageContext } from "context";

import { LANGUAGES } from "components/organisms/SelectLanguage/SelectLanguage";

function App() {
  const [currentLanguage, setLanguage] = useState(
    localStorage.getItem("language") || LANGUAGES.en
  );

  return (
    <LanguageContext.Provider value={[currentLanguage, setLanguage]}>
      <div className="czx-outer">
        <Routes />
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
