import React, { useContext, useMemo } from "react";

import { Link } from "react-router-dom";
import { LanguageContext } from "context";

import CoinzixLogoGreenWhite from "assets/images/coinzix_logo_green_white.png";

import FacebookIcon from "assets/icons/facebook.svg";
import InstagramIcon from "assets/icons/instagram.svg";
import TelegramIcon from "assets/icons/telegram.svg";
import TwitterIcon from "assets/icons/twitter.svg";
import SupportIcon from "assets/images/header/support.svg";
import TermsIcon from "assets/images/header/terms-of-use.svg";
import PrivacyIcon from "assets/images/header/privacy-policy.svg";
import CookieIcon from "assets/images/header/cookie.svg";
import RiskIcon from "assets/images/header/risk.svg";
import FeesIcon from "assets/images/header/fees.svg";
import SubmitRequestIcon from "assets/images/header/submit-request.svg";
import StatusOnlineIcon from "assets/images/header/status-online.svg";
import CryptoPricesIcon from "assets/images/header/crypto-prices.svg";

import { LANGUAGES } from "../SelectLanguage/SelectLanguage";
import {
  COOKIE_POLICY,
  PRIVACY_POLICY,
  RISK_DISCLOSURE,
  TERMS_AND_CONDITIONS,
} from "routes/route-codes";

import "./FooterCoinzix.scss";

export const ABOUT_US = {
  en: [
    {
      label: "Terms of Use",
      url: TERMS_AND_CONDITIONS,
      icon: TermsIcon,
    },
    { label: "Privacy Policy", icon: PrivacyIcon, url: PRIVACY_POLICY },
    { label: "Cookies Policy", icon: CookieIcon, url: COOKIE_POLICY },
    {
      label: "Risk Disclosure Statement",
      url: RISK_DISCLOSURE,
      icon: RiskIcon,
    },
  ],
  ro: [
    {
      label: "Termeni de Utilizare",
      url: TERMS_AND_CONDITIONS,
      icon: TermsIcon,
    },
    {
      label: "Politica de Confidențialitate",
      url: PRIVACY_POLICY,
      icon: PrivacyIcon,
    },
    {
      label: "Politica cookie-urilor",
      icon: CookieIcon,
      url: COOKIE_POLICY,
    },
    {
      label: "Declarație privind divulgarea riscurilor",
      url: RISK_DISCLOSURE,
      icon: RiskIcon,
    },
  ],
};

export const SERVICES = {
  en: [
    {
      label: "Crypto prices",
      url: "https://coinzix.com/currencies-tokens",
      icon: CryptoPricesIcon,
    },
    { label: "Fees", icon: FeesIcon, url: "https://coinzix.com/fees" },
    {
      label: "Status Online",
      icon: StatusOnlineIcon,
      url: "https://coinzix.com/status-online",
    },
  ],
  ro: [
    {
      label: "Monede și Tokeni",
      url: "https://coinzix.com/currencies-tokens",
      icon: CryptoPricesIcon,
    },
    { label: "Comisioane", icon: FeesIcon, url: "https://coinzix.com/fees" },
    {
      label: "Status Online",
      icon: StatusOnlineIcon,
      url: "https://coinzix.com/status-online",
    },
  ],
};

export const COMMUNITIES = [
  {
    label: "Facebook",
    url: "https://www.facebook.com/coinzixcom",
    icon: FacebookIcon,
  },
  {
    label: "Twitter",
    url: "https://twitter.com/coinzixcom",
    icon: TwitterIcon,
  },
  {
    label: "Instagram",
    url: "https://instagram.com/coinzixcom?utm_medium=copy_link",
    icon: InstagramIcon,
  },
  {
    label: "Telegram Exchange",
    url: "http://t.me/coinzixCEX",

    icon: TelegramIcon,
  },
  {
    label: "Telegram Token",
    url: "http://t.me/coinzixtoken",
    icon: TelegramIcon,
  },
];

export const SUPPORT = {
  en: [
    {
      label: "Submit a request",
      icon: SubmitRequestIcon,
      url: "https://coinzix.com/submit-a-request",
    },
    { label: "Support", icon: SupportIcon, url: "https://coinzix.com/support" },
    {
      label: "API Documentation",
      icon: RiskIcon,
      url: "https://docs.coinzix.com/",
    },
  ],
  ro: [
    {
      label: "Trimite o Cerere ",
      icon: SubmitRequestIcon,
      url: "https://coinzix.com/submit-a-request",
    },
    { label: "Suport", icon: SupportIcon, url: "https://coinzix.com/support" },
    {
      label: "Documentație API",
      icon: RiskIcon,
      url: "https://docs.coinzix.com/",
    },
  ],
};

export const SOCIALS = [
  {
    icon: FacebookIcon,
    url: "https://www.facebook.com/coinzixcom",
  },
  {
    icon: InstagramIcon,
    url: "https://instagram.com/coinzixcom?utm_medium=copy_link",
  },
  {
    icon: TwitterIcon,
    url: "https://twitter.com/coinzixcom",
  },
  {
    icon: TelegramIcon,
    url: "http://t.me/coinzixCEX",
  },
  {
    icon: TelegramIcon,
    url: "http://t.me/coinzixCEX",
  },
];

const FooterCoinzix = () => {
  const [currentLanguage, setCurrentLanguage] = useContext(LanguageContext);

  const ABOUT_US_LIST = useMemo(() => {
    const currentList = ABOUT_US[currentLanguage];
    return currentList;
  }, [currentLanguage]);

  const SERVICES_LIST = useMemo(() => {
    const currentList = SERVICES[currentLanguage];
    return currentList;
  }, [currentLanguage]);

  const SUPPORT_LIST = useMemo(() => {
    const currentList = SUPPORT[currentLanguage];
    return currentList;
  }, [currentLanguage]);

  return (
    <div className="czix-hompeage__footer-old">
      <div className="czx-container">
        <div className="czix-hompeage__footer-old-inner">
          <div className="czix-hompeage__footer-old__navigation">
            <ul>
              <li className="header">
                {currentLanguage === LANGUAGES.en ? "About us" : "Despre noi"}
              </li>
              {ABOUT_US_LIST.map((about) => (
                <li key={about.label}>
                  <Link to={`${currentLanguage}/${about.url}`}>
                    {about.label}
                  </Link>
                </li>
              ))}
            </ul>
            <ul>
              <li className="header">
                {currentLanguage === LANGUAGES.en ? "Service" : "Servicii"}
              </li>
              {SERVICES_LIST.map((service) => (
                <li key={service.label}>
                  <a rel="noreferrer" href={service.url}>
                    {service.label}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              <li className="header">
                {currentLanguage === LANGUAGES.en ? "Support" : "Suport"}
              </li>
              {SUPPORT_LIST.map((link) => (
                <li key={link.label}>
                  <a rel="noreferrer" href={link.url}>
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
            <ul>
              <li className="header">
                {currentLanguage === LANGUAGES.en ? "Community" : "Comunitate"}
              </li>
              {COMMUNITIES.map((community) => (
                <li key={community.label}>
                  <a
                    rel="noreferrer"
                    className="community"
                    target="_blank"
                    href={community.url}
                  >
                    <img src={community.icon} alt="" />
                    {community.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="divider" />
      <p className="copyrights">
        <a
          href="https://coinzix.com/"
          className="czix-hompeage__footer-old-logo"
        >
          <img src={CoinzixLogoGreenWhite} alt="" />
        </a>
        <span>
          &copy; {new Date().getFullYear()} Coinzix.com.{" "}
          {currentLanguage === LANGUAGES.en
            ? "All rights reserved"
            : "Toate drepturile rezervate"}
        </span>
      </p>
    </div>
  );
};

export default FooterCoinzix;
